import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const AboutUs = ({ data }) => {
    const pageTitle = "About Us"
    return (
        <Layout>
            <Seo title={pageTitle} />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>{pageTitle}</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6 order-2 ">
                                <div className="card-body h-100 services-text d-flex flex-column">
                                    <h2>Energy Logistics</h2>
                                    <p className="card-text">
                                        Delta Corp Energy Logistics is a physical supplier and trader of marine fuels and lubricants,
                                        part of a leading independent shipping company. We have an exceptional team with detailed local
                                        knowledge where you need it, who have accumulated more than 150 years of experience in the
                                        market.
                                    </p>
                                    <p>
                                        We're here to add value to your business. Reach out to us for a conversation on how we can help
                                        you.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1  d-flex justify-content-center ">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/company/rotterdam.jpg"
                                    aspectRatio={16 / 7}
                                    width={696}
                                    layout="fixed"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col  ">
                                <div className="card-body h-100  d-flex flex-column">
                                    <h2>Delta Group</h2>
                                    <p className="card-text">
                                        Delta Corp Energy Logistics is a proud member of the rapidly-expanding Delta Corp, which is a
                                        global business engaged in the shipping and logistics sectors. Present in ten different
                                        countries, Delta facilitates international trade between the world's major economies as well as
                                        emerging markets.
                                    </p>
                                    <p>
                                        It has been our goal from the outset to make a difference in the way dry bulk and tanker
                                        business is being traded. Our strong and proven management team, capital from our anchor
                                        investors, our pioneering spirit and shared commitment to the values of integrity,
                                        accountability and client focus, will help us fulfill our vision to set new standards of
                                        excellence and customer care within the dry bulk industry and beyond.
                                    </p>
                                    <p>
                                        Our commitment to the industry and service to our clients is demonstrated by our immediate
                                        global presence across the world across several time zones in Europe, Asia and the Middle East.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-md-6 order-1 ">
                                <div className="card-body h-100 services-text d-flex flex-column">
                                    <h2>Our mission</h2>
                                    <p className="card-text">
                                        Delta is a young and dynamic player in the market with a drive to do things our way. We believe
                                        in integrity, application, honesty, flexibility and giving our clients exactly what they need,
                                        when they need it and where they need it, using a good old fashioned customer service ethos.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 order-2  d-flex justify-content-center ">
                                <div className="card-body h-100 bg-dark d-flex flex-column justify-content-center">
                                    <blockquote className="blockquote text-light">
                                        "We relish solving problems and unlike other marine fuel players, we are genuine shipping and
                                        energy people who understand the needs of our customers."
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs
